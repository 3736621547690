<template>
	<div v-if="profile.role == 'SuperAdmin'">
		<v-card class="my-3">
			<v-card-title>Gerer les licences</v-card-title>
		
            <v-card-text class="my-3">
                <v-data-table :headers="headers" :items="licences" item-key="name" :search="search">
                    <template v-slot:top>
                        <v-text-field v-model="search" label="Search" class="mx-4" />
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-btn icon color="secondary" @click="openCreationModal(item)">
                            <v-icon>mdi-pencil-outline</v-icon>
                        </v-btn>
                        <v-btn icon color="error" @click="deleteLicence(item)">
                            <v-icon>mdi-delete-outline</v-icon>
                        </v-btn>
                    </template>
                </v-data-table>
                <span class="pa-3 d-flex justify-end">
                    <v-btn color="primary" fab @click="openCreationModal(null)">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </span>
            </v-card-text>
		</v-card>
		<CreationModal ref="creationModal" :data="creationModalData" :title="creationModalTitle" @save="handleSaveLicence"></CreationModal>
	</div>
</template>

<script>
    import logger from "@/assets/functions/logger"
	import Swal from 'sweetalert2/dist/sweetalert2.js'
	import CreationModal from "@/components/Licences/CreationModal.vue"

    import Licence from "@/classes/Licence"

	export default {
		name: "Licences",
		props: ["user", "profile", "notifications", "config", "currentRoom"],
		components: {
			CreationModal,
		},
		data() {
			return {
				unsub: [],
				search: "",
				licences: [],
				headers: [
					{
						text: "Nom",
						value: "name",
						sortable: true,
					},
					{
						text: "Action",
						value: "action",
						sortable: false,
					},
				],
				creationModalData: {
					name: "",
					endDate: "",
				},
                creationModalTitle: "Creer une licence",
			}
		},
		created() {
            this.unsub.push(Licence.listenAll( licences => {
                this.licences = licences
            }))
		},
		methods: {
			openCreationModal(licence) {
                if(!licence){
                    this.creationModalTitle = "Creer une licence"
                    this.creationModalData.id = null
                    this.creationModalData.name = ""
                }else{
                    this.creationModalTitle = "Modifier une licence"
                    this.creationModalData.id = licence.id
                    this.creationModalData.name = licence.name
                }
				this.$refs.creationModal.open()
			},
            handleSaveLicence(data){
                if(data.id){
                    this.updateLicence(data)
                }else{
                    this.addLicence(data)
                }
            },
			addLicence(data) {
                let tmp_licence = new Licence(null, data.name)
                tmp_licence.save()
                .then(() => {
                    logger.log(this.profile.id,"LICENCE ADD",this.profile.email + " a ajouté la licence " + data.name)
                })
			},
            updateLicence(data){
                let tmp_licence = new Licence(data.id, data.name)
                tmp_licence.save()
                .then(() => {
                    logger.log(this.profile.id,"LICENCE UPDATE",this.profile.email + " a modifié la licence " + data.name)
                })
            },
			deleteLicence(licence) {
				Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if (result.isConfirmed) {
                        licence.delete()
                        logger.log(this.profile.id,"LICENCE DELETE",this.profile.email + " a supprimé la licence " + licence.name)
					}
				})
			},
		},
		destroyed() {
			this.unsub.forEach((unsub) => unsub())
		},
	}
</script>
